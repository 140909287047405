import React, { ComponentPropsWithoutRef, forwardRef, Ref } from 'react';

import { TIME_INTERVAL_10_MIN } from '@shared/constants/timeIntervals';
import { useSqrTokenPriceQuery } from '@shared/hooks/query/token';

import { Sqr } from '@ui/uikit/components/icons/poly/Sqr';
import { cn, formatUsd } from '@ui/uikit/utils';

export type BuySqrButtonProps = ComponentPropsWithoutRef<'button'> & { label?: string };

export const BuySqrButton = forwardRef(
  ({ className, label = 'SQR', ...props }: BuySqrButtonProps, ref: Ref<HTMLButtonElement>) => {
    const { data } = useSqrTokenPriceQuery({
      refetchInterval: TIME_INTERVAL_10_MIN,
    });

    const formattedSqrPrice = data ? formatUsd(data.attributes.price) : null;

    return (
      <button
        ref={ref}
        className={cn('msq-btn msq-btn-md msq-btn-brand-gradient !rounded-full gap-1.5', className)}
        {...props}
      >
        <Sqr className="msq-btn-icon-child" />
        {label}
        <span
          className={cn('min-w-10', {
            'msq-skeleton-text': !formattedSqrPrice,
          })}
        >
          {formattedSqrPrice}
        </span>
      </button>
    );
  },
);
