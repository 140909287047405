import { useId, useState } from 'react';

import classNames from 'classnames';

import { Cross } from '@ui/uikit/components/icons/mono';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const GlobalFloatingRightBanner = ({
  className,
  userId,
}: {
  className?: string;
  userId?: number;
}) => {
  const id = useId();

  const [showAd, setIsShowAd] = useState(true);

  useAppendScript({ id, userId });

  if (!showAd) {
    return;
  }

  return (
    <div
      className={classNames(
        'fixed rounded-lg overflow-hidden z-10 shadow-xl bg-base-bg',
        className,
      )}
    >
      <div className="relative w-full h-full" id={id}>
        <button
          className="msq-btn msq-btn-overlay w-5 h-5 rounded-xl absolute right-2 top-2 z-10"
          type="button"
          onClick={() => setIsShowAd(false)}
        >
          <Cross className="icon size-4" />
        </button>
        <ins
          className={ADS_CONSTANTS.className}
          data-cp-user-id={userId}
          data-key={ADS_CONSTANTS.globalFloatingRightBannerDataKey}
        ></ins>
      </div>
    </div>
  );
};

export default GlobalFloatingRightBanner;
