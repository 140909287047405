import { useId } from 'react';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const GlobalStickyBottomBannerMobile = ({ userId }: { userId?: number }) => {
  const id = useId();

  useAppendScript({ id, userId });

  return (
    <div
      id={id}
      className="fixed left-0 bottom-0 w-full z-10 xl-msq:hidden h-[48px] p-2 bg-base-bg border-t border-tab-menu-border"
    >
      <ins
        className={ADS_CONSTANTS.className}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.globalStickyBottomBannerMobileDataKey}
      />
    </div>
  );
};

export default GlobalStickyBottomBannerMobile;
