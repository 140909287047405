import { useId } from 'react';

import classNames from 'classnames';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const GlobalStickyHeaderBannerTextButton = ({ userId }: { userId?: number }) => {
  const id = useId();

  useAppendScript({ id, userId });

  return (
    <div id={id} className="w-full overflow-hidden py-2">
      <ins
        className={classNames(ADS_CONSTANTS.className)}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.globalStickyHeaderBannerTextButtonDataKey}
      ></ins>
    </div>
  );
};

export default GlobalStickyHeaderBannerTextButton;
