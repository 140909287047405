import { useEffect } from 'react';

import { ADS_CONSTANTS } from '../AdsConstants';

export const useAppendScript = ({ id, userId }: { id: string; userId?: number }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = ADS_CONSTANTS.scriptUrl;
    script.async = true;

    const bannerContainer = document.getElementById(id);

    if (bannerContainer) {
      bannerContainer.appendChild(script);
    }

    return () => {
      if (bannerContainer) {
        bannerContainer.removeChild(script);
      }
    };
  }, [userId, id]);
};
