import { AdsToggleKey } from '@api';

type AdsEnvironment = 'production' | 'development';

type TransformShowKey<K> = K extends `show${infer Rest}` ? Rest : never;

type AdsDataKeyConfig = {
  /**
   * `data-key` Specifies a unique identifier for the ad to load and display the corresponding ad content
   */
  [K in AdsToggleKey as `${Uncapitalize<TransformShowKey<K>>}DataKey`]: string;
};

type AdsConstants = {
  scriptUrl: string;
  /**
   * Is used to define the styling and corresponds to the specific ad placement
   */
  className: string;
} & Omit<AdsDataKeyConfig, 'storeMainInlineCarouselBannerDataKey'>;

type AdsConfig = Record<AdsEnvironment, AdsConstants>;

const DEFAULT_PRODUCTION_CLASSNAME = '421b37dd';
const DEFAULT_DEVELOPMENT_CLASSNAME = '421b37dd';

const DEFAULT_PRODUCTION_URL = 'https://cdn77.aj2662.bid/519a9395.js';
const DEFAULT_DEVELOPMENT_URL = 'https://cdn77.aj2662.bid/519a9395.js';

export const ADS_CONFIG: AdsConfig = {
  production: {
    scriptUrl: DEFAULT_PRODUCTION_URL,
    globalFloatingBottomRightBannerTextButtonDataKey: '7861141648cafc1c3b7351a06d144977',
    globalFloatingRightBannerDataKey: 'e9a756ccf5946f57bc6e60d49d2c256e',
    globalStickyHeaderBannerTextButtonDataKey: '238c524afced3ed17f61eb493873e120',
    globalStickyBottomBannerMobileDataKey: 'e73490b008b8d10767d085114b9a238f',
    globalInlineProfileMiddleBannerDataKey: 'd6f91694023f1df4b7382684336c3917',
    className: DEFAULT_PRODUCTION_CLASSNAME,
  },
  development: {
    scriptUrl: DEFAULT_DEVELOPMENT_URL,
    globalFloatingBottomRightBannerTextButtonDataKey: '7861141648cafc1c3b7351a06d144977',
    globalFloatingRightBannerDataKey: 'e9a756ccf5946f57bc6e60d49d2c256e',
    globalStickyHeaderBannerTextButtonDataKey: '238c524afced3ed17f61eb493873e120',
    globalStickyBottomBannerMobileDataKey: 'e73490b008b8d10767d085114b9a238f',
    globalInlineProfileMiddleBannerDataKey: 'd6f91694023f1df4b7382684336c3917',
    className: DEFAULT_DEVELOPMENT_CLASSNAME,
  },
};
